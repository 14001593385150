.textarea {
  width: 100%;
  font-family: 'NunitoSansCustomFont';
  border: 1px solid #919799;
  border-radius: 10px;
  padding: 14px 16px;
  resize: none;
  font-size: 16px;
  box-sizing: border-box;
}

.textarea:focus {
  outline: none;
  border-color: #004c6c;
}

.error {
  border-color: #bb3034;
}

.error.textarea::placeholder {
  color: #bb3034;
}

.error.textarea:focus {
  border-color: #bb3034;
}

.width--25 {
  width: 100%;
}

.width--50 {
  width: 100%;
}

.width--75 {
  width: 100%;
}

.width--100 {
  width: 100%;
}

@media (min-width: 768px) {
  .width--25 {
    width: 50%;
  }

  .width--50 {
    width: 75%;
  }

  .width--75 {
    width: 100%;
  }

  .width--100 {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .width--25 {
    width: 25%;
  }

  .width--50 {
    width: 50%;
  }

  .width--75 {
    width: 75%;
  }

  .width--100 {
    width: 100%;
  }
}
