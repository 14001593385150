.button {
  align-items: center;
  border: none;
  color: white;
  font-weight: 600;
  width: fit-content;
  box-sizing: border-box;
  font-size: 1em;
  padding: 8px 16px;
  border-radius: 50px;
  background: none;
  font-family: 'NunitoSansCustomFont';
}

.button:hover {
  cursor: pointer;
}

.button:not(.button--inactive):focus-visible {
  box-shadow: 0px 0px 0px 4px #9be1ff;
  outline: none;
}

.button--primary:hover {
  background: #004c6c;
}

.button--primary {
  background: #006894;
}

.button--secondary {
  color: #006894;
  border: 1px solid #006894;
}

.button--secondary:hover {
  border: 2px solid #004c6c;
  color: #004c6c;
}

.button--tertiary {
  color: #006894;
  font-size: 0.875em;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.button--link {
  font-size: 0.875em;
  color: #006894;
  margin: 0;
  padding: 0;
  background-color: none;
  min-width: 0px;
}

.button--link:hover {
  color: #004c6c;
  text-decoration: underline;
}

.button--large {
  font-weight: 600;
  line-height: 24px;
  height: 3rem;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 1.125em;
}

.button--large.button--link {
  height: auto;
  margin: 0;
  padding: 0;
  font-size: 1em;
}

.button--large.button--tertiary {
  font-size: 0.875em;
  height: 1.25rem;
}

.button--inactive {
  font-weight: 400;
  color: #676d70;
}

.button--inactive:hover {
  cursor: default;
}

.button--inactive.button--primary {
  background: #d1d3d4;
}

.button--inactive.button--secondary {
  border: 1px solid #bfc3c4;
  color: #676d70;
}

.button--inactive.button--link {
  text-decoration: none;
  color: #676d70;
}

.button--full-width {
  width: 100%;
}

.button--full-width.button {
  width: 100%;
}

.button__text::slotted(*) {
  color: red;
}

.button__label {
  line-height: 56px;
}
