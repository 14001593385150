.container {
  width: auto;
  display: flex;
  margin-bottom: 10px;
}

.radio {
  width: 18px;
  height: 18px;
  position: relative;
  margin: 0px 0px;
  font-size: var(--text-md);
  color: var(--neutral-800);
  border-radius: 100%;
  border: var(--border-width-thin) solid var(--neutral-500);

  &::before {
    content: '';
    background: inherit;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
}

.container .radio:focus-within {
  outline: 4px solid #9BE1FF;
}

.radio .radio__input {
  opacity: 0;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.radio__input--filled {
  background: var(--primary-900);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;
}

.radio .radio__input:checked ~ .radio__input--filled {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
}

.radio__label {
  margin-left: 12px;
  align-self: center;
  font-family: var(--sl-font-serif);
}

.err-msg {
  font-size: var(--text-md);
  color: var(--error-700);
}
