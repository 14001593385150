body {
  background-color: #f3f4f4;
  min-height: 100vh;
  margin: 0;
  min-width: 600px;
}

a {
  color: #006894;
}

.form-wrapper {
  background-color: white;
  padding: 24px;
  border-radius: 20px;
  border: none;
  margin: auto;
  margin-bottom: 8rem;
  /* what should max-width of white box be? */
  max-width: 744px;

}

.form-content-width {
  max-width: 600px;
}

.blue-card {
  background-color: #ebf9ff;
  border: none;
  border-radius: 24px;
  padding: 32px 24px;
  margin: 24px 0px;
  margin-bottom: 24px;
}

.nav--button-container {
  display: flex;
  justify-content: space-between;
}

.success--button-container {
  display: flex;
  justify-content: flex-end;
}

.submit--button-container {
  display: flex;
  justify-content: flex-end;
}

.center-flex {
  display: flex;
  justify-content: center;
}

.divider {
  height: 1px;
  background-color: #bec3ca;
  margin-top: 36px;
  margin-bottom: 24px;
}

// class to visually hide element yet still exposed to accessibility tools - taken from bootstrap: https: //getbootstrap.com/docs/5.0/helpers/visually-hidden/
.hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.fieldset {
  margin: 0;
  margin-top: 32px;
  padding: 0;
  border: none;
  &>legend {
    padding: 0;
    margin: 0;
    font-family: var(--sl-font-serif);
  }
}

.no-margin {
  margin: 0;
}

legend.gutter-bottom {
  margin-bottom: 14px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-14 {
  margin-top: 14px;
}

.mb-38 {
  margin-bottom: 38px;
}

.color--error-600 {
  color: var(--error-600);
}

.header--bottom-border {
border-bottom: 1px solid rgba(157, 157, 159, 0.2)
}

@media(max-width: 767px) {
  body {
    background-color: white;
  }
}